<template>
  <v-container class="pa-0"
    :class="bgColorClass"
    :fluid="$vuetify.breakpoint.lgAndDown"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <!-- Enter -->
        <section
          class="top py-10 d-flex flex-column align-center"
          :style="{backgroundImage: 'url(' + topBgImg + ')', borderBottom: `10px solid ${primary}`}"
        >
          <v-img
            :src="topImg"
          ></v-img>
          <v-sheet
            class="my-5 transparent"
            align="center"
          >
            <v-img
              :src="ageAuthImg"
              width="75"
            ></v-img>
            <p>
              当サイトはアダルトコンテンツを含みます。<br />
              18歳未満の方は <a :href="exitUrl" rel="nofollow" target="_blank">こちら</a> からご退出ください。
            </p>
          </v-sheet>
          <v-card
            class="primary d-flex justify-center align-center"
            flat tile
            height="60"
            width="350"
            to="/home"
          >
            <span class="btn-enter text-overline font-weight-bold">ENTER</span>
          </v-card>
          <v-sheet
            class="free-space mt-5 transparent"
            align="center"
            v-html="topData.free_space"
          ></v-sheet>
        </section>
      </v-col>
    </v-row>

    <!-- 紹介文 -->
    <v-row no-gutters>
      <v-col cols="12"
        class="pa-5"
      >
        <v-card
          class="concept pa-3"
          :class="topData.introduction_bg_color"
        >
          <v-card-title class="my-5e pa-3 rounded-0">
            <h1 class="text-h5 font-weight-bold">{{ siteData.shop_name }}の紹介</h1>
          </v-card-title>
          <v-card-text
            :class="textColor"
            v-html="topData.introduction"
          >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import { AGE_AUTH_IMG_FOR_DARK, AGE_AUTH_IMG_FOR_LIGHT } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';


//***************************************************
//
// Component
//
//***************************************************
export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      topData: {},
      exitUrl: 'https://www.google.co.jp',
      primary: this.setting.primary_color,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    bgColorClass() {
      if (this.setting.site_bg_image_url) {
        return ''
      }
      else if (this.setting.is_dark_theme) {
        return 'secondary darken-1'
      }
      else {
        return 'secondary lighten-1'
      }
    },
    ageAuthImg() {
      return this.setting.is_dark_theme ? AGE_AUTH_IMG_FOR_DARK : AGE_AUTH_IMG_FOR_LIGHT
    },
    topImg() {
      return this.$vuetify.breakpoint.smAndUp ? this.topData.top_image_url : this.topData.top_image_mobile_url
    },
    topBgImg() {
      return this.$vuetify.breakpoint.smAndUp ? this.topData.bg_image_url : this.topData.bg_image_mobile_url
    },
    textColor() {
      return this.setting.is_dark_theme ? 'white--text' : 'black--text'
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.getTopData()
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    // API req
    getTopData() {
      this.apiTool.getRequest('top/').then( data => {
        if (!data) return
        this.topData = data
      })
      .catch(error => {
        AlertApiError(error)
      })
    },
  }
};
</script>

<style scoped>
>>> p {
  font-size: 1.1em;
}
.top {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
>>> .free-space img {
  max-width: 350px;
}
.btn-enter {
  font-size: 1.5em !important;
}
>>> .concept h2,
>>> .concept h3,
>>> .concept h4,
>>> .concept h5,
>>> .concept h6 {
  margin: 1.5em 0 1em 0;
  padding-bottom: 5px;
  font-size: 1.3em;
  line-height: 1.3em;
  border-bottom: 2px solid;
}
</style>
